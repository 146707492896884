import {IOpenDeliveryDto, OpenDeliveryProductDto} from '@heidelberg/vmi-subscription-api-client';

export enum DeliveryStatus {
    COMPLETED = 'completed',
    OPEN = 'open',
    OVERDUE_OPEN = 'overdueOpen',
    PARTLY_COMPLETED = 'partlyCompleted',
}

export interface IOpenDelivery extends IOpenDeliveryDto {
    deliveryNumber: string;
    status: DeliveryStatus;
    occurrenceNo?: number;
    totalOccurrences?: number;
}

export interface IOpenDeliveryConfirmedProduct extends OpenDeliveryProductDto {
    confirmedQuantity: number;
    isDelivered: boolean;
    isCustom?: boolean;
}
export interface IConfirmedOpenDelivery extends IOpenDelivery {
    products: IOpenDeliveryConfirmedProduct[] | undefined;
}

import {DateTime} from 'luxon';

import {IOpenDeliveryDto} from '@heidelberg/vmi-subscription-api-client';

import {DeliveryStatus, IOpenDelivery} from '@vmi/shared-models';

export type DeliveryType = 'open' | 'partly-completed' | 'completed';

export function mapDtoToOpenDelivery(openDeliveryDto: IOpenDeliveryDto, type: DeliveryType): IOpenDelivery {
    const orderNumber =
        openDeliveryDto.products && openDeliveryDto.products.length && openDeliveryDto.products[0].orderNumber;

    const deliveryNumber = openDeliveryDto.externalDeliveryNumber || orderNumber || '';

    return {
        ...openDeliveryDto,
        deliveryNumber,
        status: getDeliveryStatus(openDeliveryDto, type),
    };
}

export function getDeliveryStatus(delivery: IOpenDeliveryDto, type: DeliveryType): DeliveryStatus {
    const getOpenStatus: () => DeliveryStatus.OPEN | DeliveryStatus.OVERDUE_OPEN = () => {
        const today = DateTime.fromJSDate(new Date());
        const deliveryDate = delivery.deliveryDate ? DateTime.fromJSDate(delivery.deliveryDate) : undefined;

        if (!delivery.completedAt && deliveryDate) {
            const thresholdDate = deliveryDate.plus({ days: 1 });

            if (today >= thresholdDate) {
                return DeliveryStatus.OVERDUE_OPEN;
            }
        }
        return DeliveryStatus.OPEN;
    };

    switch (type) {
        case 'open':
            return getOpenStatus();
        case 'partly-completed':
            return DeliveryStatus.PARTLY_COMPLETED;
        case 'completed':
        default:
            return DeliveryStatus.COMPLETED;
    }
}

export function getIconClass(status: DeliveryStatus): string {
    switch (status) {
        case DeliveryStatus.COMPLETED:
            return 'hdmui-success';
        case DeliveryStatus.OVERDUE_OPEN:
            return 'hdmui-error';
        case DeliveryStatus.OPEN:
            return 'hdmui-warning-strong';
        case DeliveryStatus.PARTLY_COMPLETED: {
            return 'hdmui-warning';
        }
        default:
            return '';
    }
}

export function isDeliveryCompleted(delivery: IOpenDelivery): boolean {
    return delivery.status === DeliveryStatus.COMPLETED;
}

import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { filter, fromEvent } from 'rxjs';
import { ROUTE_PARAMS } from '../../../projects/shared-models/src';
import { getAllParams } from '../utils/util';
import { CCRoleId, DataLayer, DataLayerNull, TrafficType } from './ga4.models';

declare global {
    interface Window {
        dataLayer: (DataLayer | DataLayerNull)[];
    }
}

const PLACEHOLDER = 'X';

const dataLayerNullObject = {
    event_data: null,
};

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class Ga4Service {
    private prevPageLocation: string | undefined;
    private prevPath: string | undefined;
    private hasConsent = false;

    private readonly pageViewBuffer: (DataLayer | DataLayerNull)[] = [];

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly ccAuthService: CCAuthService,
        private readonly translateService: TranslateService
    ) {}

    public pageView(): void {
        const personSession = this.ccAuthService.getCurrentUser();
        const userId = personSession?.id ?? undefined;
        const snapshotParams = getAllParams(this.activatedRoute.snapshot);
        const organizationMembership = this.ccAuthService.getCurrentMembership();

        if (!userId || this.checkUrlBlacklisted(this.router.url)) {
            return;
        }

        let path = decodeURIComponent(this.router.url);
        path = this.pageView_urlDeleteHash(path);
        path = this.pageView_urlDeleteOrgId(path, snapshotParams[ROUTE_PARAMS.orgId]);
        if ((this.prevPath ?? document.referrer) === origin + path) {
            return;
        }
        this.prevPath = path;
        path = this.pageView_urlExchangeDeliveryNumber(path, snapshotParams[ROUTE_PARAMS.internalDeliveryNumber]);

        const dataLayerObject = {
            event: 'hcp.page_view',
            // static, do not change (string)
            ga4_event_name: 'page_view',
            // static, do not change (string)
            event_type: 'page_view',
            // static, do not change (string)
            application: 'vendor managed inventory',
            // static, do not change (string)
            page_location: origin + path,
            // dynamic, insert the full URL, see below (string)
            page_path: path,
            //dynamic, everything from page_location after the domain (string)
            page_referrer: this.prevPageLocation ?? document.referrer,
            // dynamic, insert previous page_location (modified page_location if “internal domain”, otherwise document.referrer) (string)
            org_id: this.pageView_orgId(),
            // dynamic, insert the organization ID; if a user belongs to several organizations, append the information and separate with a comma and space
            // (e.g., “a00a7a42-9a56-4df8-a49f-1f9c898a7b69, b00w4a31-3a57-4df8-a49f-1f9c898a7b69”) dynamic (string)
            ssu: this.pageView_ssus(organizationMembership?.organization.ssus || []),
            // dynamic, insert the user’s SSU; if a user belongs to several SSUs, append the information and separate with a comma and space
            // (e.g., “S450, S150, S080”) (string)
            customer_id: this.pageView_hdmCustomerNumbers(
                organizationMembership?.organization.hdmCustomerNumbers || []
            ), // dynamic, insert the user’s customer ID; if a user has several customer IDs, append the information and separate with a coma and space
            // (e.g., “823472843, 271649744”) (string)
            user_id: personSession?.id ? personSession.id : undefined,
            // dynamic (string)
            user_language: this.translateService.currentLang,
            // dynamic, keep this shorted format (string)
            user_country: personSession?.countryCode ? personSession.countryCode : undefined,
            // dynamic, keep this shorted format (string)
            user_role: this.pageView_mapRolesToReadable((organizationMembership?.ccRoles as CCRoleId[]) || []),
            // dynamic, if a user has several roles, append the information and separate with a coma (e.g., “admin, member”), lower case (string)
            traffic_type: this.pageView_trafficType(personSession?.email ?? ''),
            // dynamic, insert “internal” if user has @heidelberg.com or .ext@heidelberg.com in their mail address or if they have the postfix niceproject.eu;
            // if not “external” (string)
        };

        if (this.hasConsent) {
            if (this.pageViewBuffer.length > 0) {
                this.drainPageViewBuffer();
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(dataLayerNullObject);
            window.dataLayer.push(dataLayerObject);
        } else {
            this.pageViewBuffer.push(dataLayerObject);
        }

        this.prevPageLocation = origin + path;
    }

    public listenToEvents(): void {
        this.startUcEventSubscription();
        this.startRouterEventSubscription();
    }

    private drainPageViewBuffer(): void {
        while (this.pageViewBuffer.length > 0) {
            window.dataLayer = window.dataLayer || [];
            const entry = this.pageViewBuffer.shift();
            if (entry !== undefined && entry !== null) {
                window.dataLayer.push(dataLayerNullObject);
                window.dataLayer.push(entry);
            }
        }
    }

    private pageView_trafficType(email: string): TrafficType {
        if (email.endsWith('@heidelberg.com') || email.endsWith('@niceproject.eu')) {
            return 'internal' as TrafficType;
        } else {
            return 'external' as TrafficType;
        }
    }

    private pageView_orgId(): string | undefined {
        const activeOrgId = this.ccAuthService.getCurrentMembership()?.organizationId;
        return activeOrgId && activeOrgId.length > 0 ? activeOrgId : undefined;
    }

    private pageView_urlDeleteHash(url: string): string {
        return url.replace('/#', '');
    }

    private pageView_urlDeleteOrgId(url: string, orgId: string | null): string {
        if (orgId && url.includes(orgId)) {
            return url.replace('/' + orgId, '');
        }
        return url;
    }

    private pageView_urlExchangeDeliveryNumber(url: string, deliveryNumber: string | null): string {
        if (deliveryNumber && url.includes(deliveryNumber)) {
            return url.replace(deliveryNumber, PLACEHOLDER);
        }
        return url;
    }

    private pageView_hdmCustomerNumbers(hdmCustomerNumbers: string[]): string | undefined {
        const hdmCustomerNumbersValue = hdmCustomerNumbers.join(', ').trim();
        return hdmCustomerNumbers.length > 0 ? hdmCustomerNumbersValue : undefined;
    }

    private pageView_ssus(ssus: string[]): string | undefined {
        const ssusValue = ssus.join(', ').trim();
        return ssusValue.length > 0 ? ssusValue : undefined;
    }

    private pageView_mapRolesToReadable(ccRoles: CCRoleId[] | undefined): string | undefined {
        const roleMap: { [key in CCRoleId]: string } = {
            mdf: 'managing director finance',
            cfo: 'chief financial officer',
            buc: 'business controller',
            sar: 'sales representative',
            pur: 'purchaser',
            vpo: 'vice president operations',
            mam: 'maintenance manager',
            mao: 'maintenance operator',
            stm: 'store man',
            tra: 'trainee',
            adm: 'administrator',
            own: 'owner',
            mem: 'member',
            ppl: 'production planner',
            ppm: 'prepress manager',
            ppo: 'prepress operator',
            ppa: 'prepress assistant',
            cma: 'color manager',
            prm: 'pressroom manager',
            pro: 'press operator',
            pra: 'press assistant',
            pom: 'postpress manager',
            poo: 'postpress operator',
            poa: 'postpress assistant',
        };
        return ccRoles !== undefined && ccRoles.length > 0
            ? ccRoles
                  .map((role) => roleMap[role])
                  .join(', ')
                  .trim()
            : undefined;
    }

    private startUcEventSubscription(): void {
        fromEvent(window, 'ucEvent')
            .pipe(
                untilDestroyed(this),
                filter((e) => e instanceof CustomEvent && e.detail && e.detail.event === 'consent_status')
            )
            .subscribe((d) => {
                if ((d as CustomEvent).detail['Google Analytics 4']) {
                    this.hasConsent = true;
                    this.drainPageViewBuffer();
                    this.pageView();
                } else {
                    this.hasConsent = false;
                }
            });
    }

    private startRouterEventSubscription(): void {
        this.router.events
            .pipe(
                untilDestroyed(this),
                filter(
                    (event) => event instanceof NavigationEnd && !this.checkUrlBlacklisted(event.url) && this.hasConsent
                )
            )
            .subscribe(() => {
                this.pageView();
            });
    }

    private checkUrlBlacklisted(url: string): boolean {
        return url === '/';
    }
}

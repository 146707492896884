import {Injectable} from '@angular/core';
import {combineLatest, EMPTY, filter, map, Observable, OperatorFunction, pipe, switchMap, tap} from 'rxjs';

import {
  DocumentsClient,
  FileResponse,
  ISaveOpenDeliveryProductDto,
  OpenDeliveriesClient,
  OpenDeliveryDto,
  OpenDeliveryProductDto,
  SaveOpenDeliveryDto,
} from '@heidelberg/vmi-subscription-api-client';
import {CCAuthService} from '@heidelberg/control-center-frontend-integration/auth';

import {PrintShopsService} from '@vmi/core';
import {IConfirmedOpenDelivery, IOpenDelivery} from '@vmi/shared-models';
import {mapToRequestMetadataWithRetry, RequestMetadata} from '@vmi/utils';

import {
  DeliveryType,
  mapDtoToOpenDelivery,
} from '../../../../feature-deliveries/src/lib/functions/delivery-status.functions';
import {
  getDeliveryProductTempId
} from '../../../../feature-deliveries/src/lib/functions/get-delivery-product-working-id.function';

// TBD: split into delivery service and delivery api service

interface DeliveriesAPI {
    openDeliveriesAPI: OpenDeliveryDto[];
    completedDeliveriesAPI: OpenDeliveryDto[];
}

interface GroupedDeliveries {
    fullyOpenDeliveries: OpenDeliveryDto[];
    partlyCompletedDeliveries: OpenDeliveryDto[];
    fullyCompletedDeliveries: OpenDeliveryDto[];
}

type MappedDeliveries = ReturnType<typeof mapDtoToOpenDelivery>[];

@Injectable({
    providedIn: 'root',
})
export class InventoryDeliveryService {
    #originalOpenDeliveries: OpenDeliveryDto[] = [];

    constructor(
        private readonly openDeliveriesClient: OpenDeliveriesClient,
        private readonly documentsClient: DocumentsClient,
        private readonly sessionService: CCAuthService,
        private readonly printShopsService: PrintShopsService
    ) {}

    public getOpenDeliveriesXlsx(deliveryNumbers: string[]): Observable<FileResponse> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.documentsClient.getOpenDeliveriesXlsx(
                    selectedPrintshop?.customerNumber,
                    deliveryNumbers,
                    this.sessionService.getCurrentUser()?.locale
                )
            )
        );
    }

    public getOriginalOpenDeliveries(): OpenDeliveryDto[] {
        return this.#originalOpenDeliveries;
    }

    public getAllDeliveries(): Observable<RequestMetadata<IOpenDelivery[]>> {
        this.#originalOpenDeliveries = [];

        const openDeliveries$ = this.fetchDeliveries(false).pipe(this.assignOriginalOpenDeliveries());
        const completedDeliveries$ = this.fetchDeliveries(true);

        return combineLatest([openDeliveries$, completedDeliveries$]).pipe(
            map(
                ([openDeliveriesAPI, completedDeliveriesAPI]) =>
                    ({ openDeliveriesAPI, completedDeliveriesAPI }) as DeliveriesAPI
            ),
            this.groupDeliveries(),
            this.mapDeliveries(),
            this.handleDeliveries(),
            mapToRequestMetadataWithRetry()
        );
    }

    public postOpenDelivery(openDelivery: IConfirmedOpenDelivery): Observable<void> {
        const originalOpenDelivery = this.#originalOpenDeliveries.find(
            (d) => openDelivery.internalDeliveryNumber === d.internalDeliveryNumber
        );

        if (!originalOpenDelivery) {
            return EMPTY;
        }

        const products = this.prepareProductsPayload(openDelivery, originalOpenDelivery);

        return combineLatest([this.printShopsService.selectedPrintShop$, this.printShopsService.hasWriteRights$]).pipe(
            filter(([selectedPrintshop, hasWriteRights]) => !!selectedPrintshop && hasWriteRights),
            switchMap(([selectedPrintshop]) =>
                this.openDeliveriesClient.post({
                    customerNumber: selectedPrintshop?.customerNumber,
                    internalDeliveryNumber: openDelivery.internalDeliveryNumber,
                    products,
                } as SaveOpenDeliveryDto)
            )
        );
    }

    private prepareProductsPayload(
        openDelivery: IConfirmedOpenDelivery,
        originalOpenDelivery: OpenDeliveryDto
    ): ISaveOpenDeliveryProductDto[] {
        return (
            openDelivery.products
                ?.filter((product) => originalOpenDelivery.products?.map((p) => p.id).includes(product.id))
                ?.map(
                    (product) =>
                        ({
                            isAdditional: product.isAdditional,
                            isoCode: (product.isCustom && product.isoCode) || null,
                            position: product.position,
                            productId: product.id,
                            quantity: product.confirmedQuantity,
                            materialDescription: (product.isCustom && product.name) || null,
                        }) as ISaveOpenDeliveryProductDto
                ) || []
        );
    }

    private fetchDeliveries(onlyCompleted: boolean): Observable<OpenDeliveryDto[]> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.openDeliveriesClient.get(
                    selectedPrintshop?.customerNumber,
                    this.sessionService.getCurrentUser()?.locale,
                    onlyCompleted
                )
            )
        );
    }

    private assignOriginalOpenDeliveries(): OperatorFunction<OpenDeliveryDto[], OpenDeliveryDto[]> {
        return pipe(
            tap((openDeliveries) => {
                this.#originalOpenDeliveries = openDeliveries;
            })
        );
    }

    private groupDeliveries(): OperatorFunction<DeliveriesAPI, GroupedDeliveries> {
        return pipe(
            map(({ openDeliveriesAPI, completedDeliveriesAPI }) => {
                const mappedCompletedDeliveries = this.takeOnlyUniqueCompletedDeliveries(completedDeliveriesAPI).map(
                    (delivery) => this.mergeProductsAndSortByPosition(delivery)
                );

                const openIds = new Set(openDeliveriesAPI.map((d) => d.internalDeliveryNumber));
                const completedIds = new Set(mappedCompletedDeliveries.map((d) => d.internalDeliveryNumber));
                const intersectingIds = new Set([...completedIds].filter((id) => openIds.has(id)));

                const isIntersecting =
                    () =>
                    (delivery: OpenDeliveryDto): boolean =>
                        intersectingIds.has(delivery.internalDeliveryNumber);

                const notIntersecting =
                    () =>
                    (delivery: OpenDeliveryDto): boolean =>
                        !intersectingIds.has(delivery.internalDeliveryNumber);

                return {
                    fullyOpenDeliveries: openDeliveriesAPI.filter(notIntersecting()),
                    partlyCompletedDeliveries: this.mergeIntoPartlyCompletedDeliveries(
                        intersectingIds,
                        openDeliveriesAPI.filter(isIntersecting()),
                        mappedCompletedDeliveries.filter(isIntersecting())
                    ),
                    fullyCompletedDeliveries: mappedCompletedDeliveries.filter(notIntersecting()),
                };
            })
        );
    }

    private mapDeliveries(): OperatorFunction<GroupedDeliveries, MappedDeliveries> {
        return pipe(
            map(({ fullyOpenDeliveries, partlyCompletedDeliveries, fullyCompletedDeliveries }) => {
                const deliveryGroups: { deliveries: OpenDeliveryDto[]; type: DeliveryType }[] = [
                    { deliveries: fullyOpenDeliveries, type: 'open' },
                    { deliveries: partlyCompletedDeliveries, type: 'partly-completed' },
                    { deliveries: fullyCompletedDeliveries, type: 'completed' },
                ];

                return deliveryGroups.reduce(
                    (acc, { deliveries, type }) => [
                        ...acc,
                        ...deliveries.map((delivery) => mapDtoToOpenDelivery(delivery, type)),
                    ],
                    [] as MappedDeliveries
                );
            })
        );
    }

    private handleDeliveries(): OperatorFunction<MappedDeliveries, MappedDeliveries> {
        return pipe(
            tap((deliveries) => {
                this.prepareExternalDeliveryNumberOccurrences(deliveries);
            })
        );
    }

    private mergeProductsAndSortByPosition(delivery: OpenDeliveryDto): OpenDeliveryDto {
        const productsMap = new Map<string, OpenDeliveryProductDto>();

        for (const product of delivery.products || []) {
            if (!product.id || !product.orderNumber) {
                continue;
            }

            const productId = getDeliveryProductTempId(product);

            if (!productsMap.has(productId)) {
                productsMap.set(productId, product);
            } else {
                const existingProduct = productsMap.get(productId);

                if (
                    existingProduct &&
                    existingProduct.id &&
                    existingProduct.confirmedQuantity !== undefined &&
                    product.confirmedQuantity !== undefined &&
                    existingProduct.confirmedQuantity < product.confirmedQuantity
                ) {
                    productsMap.set(productId, product);
                }
            }
        }

        const products = [...productsMap.values()];
        products.sort((a, b) => a.position - b.position);

        return {
            ...delivery,
            products,
        } as OpenDeliveryDto;
    }

    private takeOnlyUniqueCompletedDeliveries(completedDeliveries: OpenDeliveryDto[]): OpenDeliveryDto[] {
        const uniqueMap = new Map<string, OpenDeliveryDto>();

        for (const delivery of completedDeliveries) {
            // Ignore corrupted data
            if (!delivery.internalDeliveryNumber || !delivery.externalDeliveryNumber || !delivery.completedAt) {
                continue;
            }

            if (!uniqueMap.has(delivery.internalDeliveryNumber)) {
                uniqueMap.set(delivery.internalDeliveryNumber, delivery);
            } else {
                const existingDelivery = uniqueMap.get(delivery.internalDeliveryNumber);

                if (existingDelivery?.completedAt && delivery.completedAt > existingDelivery.completedAt) {
                    uniqueMap.set(delivery.internalDeliveryNumber, delivery);
                }
            }
        }

        return [...uniqueMap.values()];
    }

    private mergeIntoPartlyCompletedDeliveries(
        intersectingIds: Set<string | undefined>,
        intersectingOpenDeliveries: OpenDeliveryDto[],
        intersectingCompletedDeliveries: OpenDeliveryDto[]
    ): OpenDeliveryDto[] {
        const partlyCompletedDeliveries: OpenDeliveryDto[] = [];

        [...intersectingIds.values()].forEach((intersectingId) => {
            const findByInternalDeliveryNumber =
                () =>
                (delivery: OpenDeliveryDto): boolean =>
                    delivery.internalDeliveryNumber === intersectingId;

            const openDelivery = intersectingOpenDeliveries.find(findByInternalDeliveryNumber());
            const completedDelivery = intersectingCompletedDeliveries.find(findByInternalDeliveryNumber());

            if (openDelivery && completedDelivery) {
                const completedProducts: OpenDeliveryProductDto[] =
                    completedDelivery.products?.filter(
                        (product) =>
                            !openDelivery.products
                                ?.map((p) => getDeliveryProductTempId(p))
                                ?.includes(getDeliveryProductTempId(product))
                    ) || [];

                const openProducts: OpenDeliveryProductDto[] = openDelivery.products || [];

                const products: OpenDeliveryProductDto[] = [...completedProducts, ...openProducts];
                products.sort((a, b) => a.position - b.position);

                partlyCompletedDeliveries.push({ ...openDelivery, products } as OpenDeliveryDto);
            }
        });

        return partlyCompletedDeliveries;
    }

    private prepareExternalDeliveryNumberOccurrences(deliveries: IOpenDelivery[]): void {
        const externalNumberOccurrencesMap: Map<string, number> = new Map<string, number>();

        deliveries.forEach((delivery) => {
            const deliveryNumber = delivery.deliveryNumber;

            if (externalNumberOccurrencesMap.has(deliveryNumber)) {
                const occurrences = (externalNumberOccurrencesMap.get(deliveryNumber) || 1) + 1;
                delivery.occurrenceNo = occurrences;
                externalNumberOccurrencesMap.set(deliveryNumber, occurrences);
            } else {
                delivery.occurrenceNo = 1;
                externalNumberOccurrencesMap.set(deliveryNumber, 1);
            }
        });

        deliveries.forEach((delivery) => {
            delivery.totalOccurrences = externalNumberOccurrencesMap.get(delivery.deliveryNumber) || 1;
        });
    }
}
